import { Box, Card, CardContent, Container, Grid } from '@mui/material'
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs'
import Page from 'components/Page'
import EditCounterParies from 'components/pagewise/counterparties/EditCounterParies'
import PartiesContactList from 'components/pagewise/counterparties/PartiesContactList'
import PartiesContractList from 'components/pagewise/counterparties/PartiesContractList'
import Spinner from 'components/Spinner'
import useLocales from 'hooks/useLocales'
import { useLocation, useParams } from 'react-router'
import { useFetchContentListQuery } from 'redux/api/contentApi'
import { useFetchPartyByUUIDQuery } from 'redux/api/partyAPI'
import { useState } from 'react'

type Props = {}

const EditCounterPartiesPage = (props: Props) => {
    const { translate } = useLocales()
    const { id } = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page');
    const rowsPerPageParam = searchParams.get('rowsPerPage');

    const [sortField, setSortField] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('ASC');

    const { data, isLoading: isLoadingData, isFetching } = useFetchPartyByUUIDQuery(id!, { skip: !id })
    const { data: content, isLoading: isLoadingContent } = useFetchContentListQuery({ page: pageParam ? +pageParam : 1, rowsPerPage: rowsPerPageParam ? +rowsPerPageParam : 10, parties: id, onlyContracts: true, search: true, sortField: sortField, sortOrder: sortOrder })

    const handleSortChange = (column: string, direction: 'ASC' | 'DESC') => {
        setSortField(column);
        setSortOrder(direction);
    };

    const isLoading = isLoadingData || isFetching
    let contactContent = null
    let contractContent = null
    let contentData = <Box component={'div'} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <Spinner />
    </Box>


    if (!isLoading) {
        contactContent = <PartiesContactList contacts={data?.contacts} />

    }
    if (!isLoadingContent && content) {
        contractContent = <PartiesContractList contents={content} onSortChange={handleSortChange} />
    }


    if (!isLoading) {
        contentData = <>
            <HeaderBreadcrumbs heading={data?.name} links={[]} />
            <Grid container spacing={3}>
                <Grid item sm={6}>
                    <EditCounterParies data={data} isLoading={isLoading} />
                </Grid>
                <Grid item sm={6}>
                    {contactContent}
                </Grid>
                <Grid item sm={12}>
                    {contractContent}
                </Grid>
            </Grid>
        </>
    }

    return <Page title={translate('parties.edit', 'Edit parties')}>
        <Container maxWidth={false}>
            {contentData}
        </Container>
    </Page>
}

export default EditCounterPartiesPage