import moment from "moment";

export function FormatDate(date: Date) {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const hour = `0${date.getHours()}`.slice(-2);
    const minute = `0${date.getMinutes()}`.slice(-2);
    const second = date.getSeconds();

    return `${year}-${month}-${day} ${hour}:${minute}`;

    // return `${date.getFullYear()}-` + `0${date.getMonth() + 1}`.slice(-2) +"-"+ `0${date.getDate()}`.slice(-2) + " " + `0${date.getHours()}`.slice(-2) + ":" + `0${date.getMinutes()}`.slice(-2)




}

export const isValidDate = (txt: any) => moment(txt).isValid()


export const getDateFormatLabel = (word: string, translate: any) => {
    let itm = '';
    switch (word) {
        case 'DAY':
        case 'D':
            itm = translate('common.days', 'Days');
            break;
        case 'WEEK':
        case 'W':
            itm = translate('common.weeks', 'Weeks');
            break;
        case 'MONTH':
        case 'M':
            itm = translate('common.months', 'Months');
            break;
        case 'YEAR':
        case 'Y':
            itm = translate('common.years', 'Years');
            break;

        default:
            break;
    }

    return itm
};

export const getFullDateFormat = (letter: string) => {
    let word = '';

    switch (letter) {
        case 'D':
            word = 'DAY'
            break;
        case 'W':
            word = 'WEEK'
            break;
        case 'M':
            word = 'MONTH'
            break;
        case 'Y':
            word = 'YEAR'
            break;
        default:
            word = letter
    }
    return word

}