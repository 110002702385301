// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { useFetchOrganizationByIdQuery } from 'redux/api/organizationsAPI';
import Gleap from 'gleap';
import { GLEAP_API } from './config';

// ----------------------------------------------------------------------

export default function App() {
  const { user, setOrganization } = useAuth()
  const token: any = window.localStorage.getItem('accessToken');
  const { data } = useFetchOrganizationByIdQuery({ id: user?.organization?.id!, token }, { skip: !user?.organization?.id || !token })

  //Force reload the users browser when there is a new release is available(Cache busting)
  useEffect(() => {
    if (data) {
      setOrganization(data)
    }
  }, [data, setOrganization])

  useEffect(() => {
    const version = process.env.REACT_APP_VERSION!;
    const storedVersion = localStorage.getItem('appVersion');

    if (version !== storedVersion) {
      window.location.replace(window.location.href);
    }

    localStorage.setItem('appVersion', version);
  }, []);

  /* useEffect(() => {
    //Display Gleap only for specific users
    const langStorage = localStorage.getItem('i18nextLng') || 'sv';
    Gleap.setLanguage(langStorage);
    const showGleap = user?.organization?.name === 'MartinTestStage AB';

    if (showGleap) {
      Gleap.initialize(GLEAP_API);
    }
  }, [user]) */

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                {/* <Settings /> */}
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
