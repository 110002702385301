import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";
import moment from 'moment';

export type IUserSuggestion = { email: string, id: string, name: string, uuid: string }

export const userApi = createApi({
    reducerPath: "API/USER",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['userSuggestions', 'users'],
    endpoints(builder) {
        return {
            fetchUserSuggestions: builder.query<IUserSuggestion[], void>({
                query() {

                    let endpointURL = `/us-api/users/suggestions`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["userSuggestions"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),
            fetchUsers: builder.query<any, { page?: number; rowsPerPage?: number }>({
                query({ page = 1, rowsPerPage = 10 }) {
                    const endpointURL = `/us-api/users?pageSize=${rowsPerPage}&pageNumber=${page}`;
                    return {
                        url: endpointURL
                    };
                },
                transformResponse(response: any) {
                    return {
                        rows: response.content.map((e: any) => ({
                            ...JSON.parse(JSON.stringify(e)),
                            lastLoginAt: e.lastLoginAt ? moment.utc(e.lastLoginAt).local() : null
                        })),
                        totalRecords: response.pagination.totalRecords
                    };
                },
                providesTags: ["users"],
                keepUnusedDataFor: 60 * 10 // 10 minutes cache
            })

        }
    }
})

export const { useFetchUserSuggestionsQuery, useFetchUsersQuery } = userApi