import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Typography,
  Button,
  Box,
  Autocomplete,
  TextField,
  Badge,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';
import { Permission } from './manage-access.types';
import _get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import useLocales from 'hooks/useLocales';
import ManageAccessDropDown from './manage-access-drop-down';
import { LoadingButton } from '@mui/lab';
import { sortPermissions } from 'utils';
import { useDispatch, useSelector } from 'redux/store';
import { setPermissions } from 'redux/slices/permissions/permissionsSlice';

type ManageAccessDialogProps = {
  open: boolean;
  onClose: () => void;

  handleSave: (permissions: Permission[]) => Promise<void>;

  contractName: string;
  contractId: number;
  userList: Member[];
  saveLocal?: boolean;
};

type Member = {
  id: string;
  name: string;
  type: string;
  email: string;
};

const avatar_colors = [
  { letter: 'A', color: '#ff7961' },
  { letter: 'B', color: '#ba68c8' },
  { letter: 'C', color: '#4db6ac' },
  { letter: 'D', color: '#7986cb' },
  { letter: 'E', color: '#a1887f' },
  { letter: 'F', color: '#e57373' },
  { letter: 'G', color: '#64b5f6' },
  { letter: 'H', color: '#81c784' },
  { letter: 'I', color: '#ffb74d' },
  { letter: 'J', color: '#90a4ae' },
  { letter: 'K', color: '#9575cd' },
  { letter: 'L', color: '#4fc3f7' },
  { letter: 'M', color: '#aed581' },
  { letter: 'N', color: '#dce775' },
  { letter: 'O', color: '#ff8a65' },
  { letter: 'P', color: '#4dd0e1' },
  { letter: 'Q', color: '#4db6ac' },
  { letter: 'R', color: '#f06292' },
  { letter: 'S', color: '#7986cb' },
  { letter: 'T', color: '#a1887f' },
  { letter: 'U', color: '#e57373' },
  { letter: 'V', color: '#64b5f6' },
  { letter: 'W', color: '#81c784' },
  { letter: 'X', color: '#ffb74d' },
  { letter: 'Y', color: '#90a4ae' },
  { letter: 'Z', color: '#9575cd' },
];

export const ManageAccessDialog: React.FC<ManageAccessDialogProps> = ({
  open,
  onClose,

  handleSave,
  saveLocal,
  contractName,
  contractId,
  userList,
}) => {
  const { permissions } = useSelector((store) => store.permissionsStore);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getColor = (letter: string) => {
    const color = avatar_colors.find((color) => color.letter === letter.toUpperCase());
    return color ? color.color : '#000';
  };

  const removePermission = (permissionId: number) => {
    handleRemovePermission(permissionId);
  };

  const { translate } = useLocales();

  const [permissionsSelected, setPermissionsSelected] = React.useState<Member[]>([]);
  const [permissionType, setPermissionType] = React.useState('Editor');

  const addPermission = () => {
    addPermissions(permissionsSelected, permissionType);

    setPermissionsSelected([]);
    setPermissionType('Editor');
  };

  // const [permissions, setPermissions] = React.useState<Permission[]>([]);
  const [isDirty, setIsDirty] = React.useState(false);

  // useEffect(() => {
  //   if (permissions.length) {
  //     dispatch(setPermissions(permissions))
  //   }
  // }, [permissions]);

  const addPermissions = (newPermissions: Member[], type: string) => {
    setIsDirty(true);
    // @ts-ignore
    const updatedPermissions: Permission[] = newPermissions.map((permission) => ({
      name: permission.name,
      avatarUrl: '',
      isGroup: permission.type === 'group',
      email: permission?.email,
      permission: { permissionId: type === 'Viewer' ? 2 : 1, permissionName: type },
      id: permission.id,
      permissionName: type,
      type: permission.type,
      permissionId: type === 'Viewer' ? 2 : 1,
      saveable: true,
    }));

    const newPermissionsList = [...permissions];

    updatedPermissions.forEach((permission) => {
      if (!permissions.find((p) => +p.id === +permission.id)) {
        newPermissionsList.push(permission);
      } else {
        const index = newPermissionsList.findIndex((p) => +p.id === +permission.id);
        newPermissionsList[index] = permission;
      }
    });

    dispatch(setPermissions(sortPermissions(newPermissionsList)));
  };

  const handleRemovePermission = async (permissionId: number) => {
    setIsDirty(true);
    const data = {
      contractId,
      userInfos: [],
      userGroupInfos: [],
    };
    const permission = permissions.find((permission) => permission.id === permissionId);

    if (permission?.type === 'user') {
      // @ts-ignore
      data.userInfos.push({ userId: permission.id });
    } else {
      // @ts-ignore
      data.userGroupInfos.push({ userGroupId: permission.id });
    }
    // await privateAxiosInstance.delete('cs-api/contractPermission', {
    //   data,
    // });

    dispatch(
      setPermissions(
        sortPermissions(permissions.filter((permission) => permission.id !== permissionId))
      )
    );
  };

  const makeAViewer = (permissionId: number) => {
    setIsDirty(true);
    const updatedPermissions = permissions.map((permission) => {
      if (permission.id === permissionId) {
        return {
          ...permission,
          permissionName: 'Viewer',
          permission: { permissionName: 'Viewer', permissionId: 2 },
        };
      }
      return permission;
    });

    dispatch(setPermissions(sortPermissions(updatedPermissions)));
  };

  const makeAnEditor = (permissionId: number) => {
    setIsDirty(true);
    const updatedPermissions = permissions.map((permission) => {
      if (permission.id === permissionId) {
        return {
          ...permission,
          permissionName: 'Editor',
          permission: { permissionName: 'Editor', permissionId: 1 },
        };
      }
      return permission;
    });
    dispatch(setPermissions(sortPermissions(updatedPermissions)));
  };

  const handleSaveLocal = async () => {
    setIsLoading(true);
    await handleSave(permissions);
    setIsLoading(false);
    setIsDirty(false);
  };

  const handleClose = () => {
    onClose();
    dispatch(setPermissions(sortPermissions(permissions)));
    setIsDirty(false);
  };

  const getUniquePermissions = (sortedPermissionsArray: Permission[]) => {
    const uniqueItems = uniqBy(sortedPermissionsArray, 'id');
    return uniqueItems;
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {translate('contractPermission.Share', 'Share')}: {contractName}
      </DialogTitle>
      <DialogContent>
        {/* MUI Autocomplete component for adding people and groups */}
        <Box sx={{ my: 2, display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            <Autocomplete
              multiple
              id="shareUserAndGroup"
              options={userList}
              getOptionLabel={(user) => _get(user, 'name')}
              isOptionEqualToValue={(option, value) => _get(option, 'name') === _get(value, 'name')}
              filterSelectedOptions
              value={permissionsSelected}
              onChange={(event, newValue) => {
                setPermissionsSelected(newValue);
              }}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.type === 'group' && (
                    <GroupIcon sx={{ height: '16px', width: '16px', mr: 1 }} />
                  )}
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate('contractPermission.addGroupsAndUsers', 'Add groups and users')} 
                />
              )}
            />
          </Box>
          {permissionsSelected && permissionsSelected.length > 0 && (
            <Box sx={{ ml: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="permissions-label">
                  {translate('contractPermission.permission', 'Permission')}
                </InputLabel>
                <Select
                  labelId="permissions-label"
                  label="Permission"
                  variant="outlined"
                  sx={{ width: '100%' }}
                  // size="small"
                  placeholder="Permission Type"
                  value={permissionType}
                  onChange={(event) => setPermissionType(event.target.value)}
                >
                  <MenuItem value="Viewer">
                    {translate('contractPermission.viewer', 'Viewer')}
                  </MenuItem>
                  <MenuItem value="Editor">
                    {translate('contractPermission.editor', 'Editor')}
                  </MenuItem>
                </Select>
              </FormControl>

              {permissionsSelected && permissionsSelected.length > 0 && (
                <Box sx={{ flex: 1, mt: 1 }}>
                  <Button
                    variant="contained"
                    sx={{ width: '100px', justifyContent: 'space-evenly' }}
                    onClick={addPermission}
                  >
                    <AddIcon />
                    {translate('common.add', 'Add')}
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Typography sx={{ color: '#637381', fontWeight: 600 }}>
              {translate('contract.whoHasAccess', `Who has access`)}
            </Typography>
          </Box>
        </Box>
        {/* People with access subtitle */}
        {permissions.length &&
          getUniquePermissions(sortPermissions(permissions)).map((permission) => (
            <Box key={permission.id} sx={{ display: 'flex', mb: 2 }}>
              <Box>
                {permission.isGroup && (
                  <Badge
                    badgeContent={<GroupIcon sx={{ height: '14px', width: '14px' }} />}
                    color="primary"
                    // sx={{ mr: 1 }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    overlap="circular"
                  >
                    <Avatar
                      sx={{
                        backgroundColor: getColor(permission.name.charAt(0)),
                        color: 'white',
                      }}
                    >
                      {permission.name.charAt(0).toUpperCase()}
                    </Avatar>
                  </Badge>
                )}
                {!permission.isGroup && (
                  <Avatar
                    sx={{
                      backgroundColor: getColor(permission.name.charAt(0)),
                      color: 'white',
                    }}
                  >
                    {permission.name.charAt(0).toUpperCase()}
                  </Avatar>
                )}
              </Box>
              <Box sx={{ flex: 1, marginLeft: '1rem' }}>
                <Typography sx={{ color: '#1f1f1f', fontSize: '1rem', fontWeight: 'bold' }}>
                  {permission.name}
                </Typography>
                <Typography sx={{ color: '#444746', fontSize: '14px' }} variant="body2">
                  {permission.email}
                </Typography>
              </Box>
              <Box>
                <ManageAccessDropDown
                  permission={permission}
                  makeAViewer={makeAViewer}
                  makeAnEditor={makeAnEditor}
                  removePermission={removePermission}
                  show={permission.name.toLowerCase() === 'admins' ? false : true}
                />
              </Box>
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        {!isDirty && (
          <Button onClick={handleClose} variant="outlined" sx={{}}>
            {translate('common.close', 'Close')}
          </Button>
        )}
        {isDirty && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ color: '#FF5630', fontStyle: 'italic' }}>
              {translate('common.pendingChanges', 'Pending changes')}
            </Typography>
            <LoadingButton
              color="primary"
              loading={isLoading}
              variant="contained"
              sx={{ ml: 1 }}
              onClick={handleSaveLocal}
            >
              {saveLocal ? translate('common.add', 'Add') : translate('common.save', 'Save')}
            </LoadingButton>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};
