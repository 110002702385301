import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
import Page from '../components/Page';
import useLocales from 'hooks/useLocales';
import LockIcon from '@mui/icons-material/Lock';
import { clearError } from 'redux/slices/errorSlice';
import { useDispatch } from 'redux/store';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page403() {
    const { translate } = useLocales();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleClickGoback = () => {
        dispatch(clearError());
        navigate(-1)
    }

    return (
        <Page title="403 Internal Server Error" sx={{ height: '100vh' }}>
            <RootStyle>
                <Container>
                    <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
                        <Typography variant="h4" paragraph sx={{ display: 'inline-flex', alignItems: 'end', gap: 1, }}>
                            <LockIcon sx={{ fontSize: 40 }} />
                            {translate('403.accessDenied', 'Access denied')}
                        </Typography>

                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            {translate('403.accessDeniedMessage', "Either you don't have access to this page or this page simply doesn't exist.")}
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: 2 }}>
                            {translate('errorPage.goBackToThePreviousPage', "You can try to go back to the previous page.")}
                        </Typography>
                        <Button
                            onClick={() => handleClickGoback()}
                            variant="outlined"
                            color="primary"
                            className="retry-btn"
                        >
                            {translate('errorPage.goBack', 'Go back')}
                        </Button>
                    </Box>
                </Container>
            </RootStyle>
        </Page>
    );
}