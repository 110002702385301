import { Party } from 'pages/contracts/contract-types';
import { Dispatch, SetStateAction } from 'react';

type SelectedType = string[];
export interface IContractItem {
  folderType: string;
  id: string;
  recordType: string;
  createdAt: string;
  contractDetail: {
    title: string;
    endDate: string;
    noticePeriod: number;
    noticePeriodType: string;
    source: string;
    terminationDeadlineDate: string;
  };
  contractType: {
    type: string;
    translationKey: string;
  };
  contractOwner: {
    userInfo: {
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      mobileNo: string;
    };
    uuid: string;
  };
  status: string;
  handleDelete?: (ids: string) => void;
  contractParties: Party[];
  contractStatus: string;
  contractOwnerName: string;
  expired: boolean;
  contentType: 'folder' | 'contract';
  owner: {
    userInfo: {
      name: string;
    };
  };
  permission: {
    permissionName: 'Viewer' | 'Editor';
  };
  driveTable?: boolean;
  uuid: string;
  name: string;
  ocrProcessingStatus: string;
  files?: Array<any>;
  folder?: IParentFolder;
  suggestedContractOwners?: any;
  aiSuggestionStatus?: string;
  setSelected?: Dispatch<SetStateAction<SelectedType>>;
  contractPeriods?: Array<any>
};
export interface IContractItemWIthOwnerObject extends IContractItem {
  contractOwner: {
    userInfo: {
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      mobileNo: string;
    },
    uuid: string
  }
}
export type IContractFolderItem = {
  id: number;
  uuid: string;
  name: string;
  inbox: IInboxItem;
  createdAt: string;
  createdBy: number;
  lastModifiedAt: string;
  lastModifiedBy: string;
  deletedAt: string;
  deletedBy: string;
  folderType: string;
  parentFolder: IFolder | null;
  folderPath: IFolder[];
  permission: IPermissionObject;
  contentType?: "folder" | "contract"
};
type IParentFolder = {
  id: number;
  uuid: string;
  name: string;
  folderPath?: IFolder[];
  folderType?: "SYSTEM_GENERATED" | "USER_GENERATED";
};
export type IFolder = {
  id: number;
  uuid: string;
  name: string;
  parentFolder: IParentFolder | null;
};
type IInboxItem = {
  id: number;
  uuid: string;
  name: string;
  // permission: 'Viewer' | 'Editor'
};

export type IContractListItem = {
  id: string;
  contractDetail: {
    title: string;
    endDate: string;
    noticePeriod: number;
    noticePeriodType: string;
  };
  contractType: {
    type: string;
  };
  contractOwner: {
    userInfo: {
      firstName: string;
      lastName: string;
    };
  };
  contractOwnerName: string;
  status: string;
};

export type IPermissionObject = {
  permissionName: 'Viewer' | 'Editor';
};

export enum IGenerateType {
  DETAILS = "DETAILS",
  INSIGHTS = "INSIGHTS",
  CUSTOM = "CUSTOM"
}
export enum ITabType {
  insights = "insights",
  details = "details",
  custom = "custom"
}