import { styled, Grid } from '@mui/material'
import MagicWand from 'components/contract/MagicWand'
import FMSelectInput from 'components/reusable/formik-inputs/FMSelectInput'
import FMTextInput from 'components/reusable/formik-inputs/FMTextInput'
import { IContractFormValues } from 'constants/initialValues/contractFormInitialValues'
import { useEditContractProvider } from 'contexts/EditContractContext'
import { FormikProps } from 'formik'
import useLocales from 'hooks/useLocales'

type Props = {
    formik: FormikProps<IContractFormValues>
}

const BasicDetails = ({ formik }: Props) => {
    const { setFieldValue } = formik
    const { translate } = useLocales()
    const { contractTypes, contract, handleHighlight } = useEditContractProvider()

    const contractTypesSuggestions = contract?.aiSuggestionResponse?.contractTypes[0]
    const optionsContractTypes = contractTypes.map(e => ({ value: e.contractTypes[0]?.id, label: e.contractTypes[0]?.type }))

    return (
        <div>
            {/* --------Title--------- */}
            <Grid item md={12} mb={2}>
                <FMTextInput name="title" label={translate('contract.title', 'Contract title')} />
            </Grid>

            {/* --------Contract Type--------- */}
            <Grid item container alignContent={"center"} alignItems={"center"} md={12} >
                <SelectFieldWrapper >
                    <FMSelectInput
                        name='contractType'
                        label={translate("basicDetails.contractType", "Contract type")}
                        options={optionsContractTypes}
                    />

                    {contractTypesSuggestions && <MagicWand
                        handleHighlight={handleHighlight}
                        keyText={contractTypesSuggestions.contractType}
                        lineText={contractTypesSuggestions.lineText}
                        setFieldValue={setFieldValue}
                        field='contractType'
                        fieldValue={2}
                    />}

                </SelectFieldWrapper>
            </Grid>
        </div>
    )
}

export default BasicDetails

const SelectFieldWrapper = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    '.MuiInputBase-root': {
        flex: 1,
        // transform: 'translate(3px, -5px) scale(0.75)'
    }
}))