
import { Grid, Typography, styled } from '@mui/material'
import MagicWand from 'components/contract/MagicWand'
import FMDatePicker from 'components/reusable/formik-inputs/FMDatePicker'
import FMSelectInput from 'components/reusable/formik-inputs/FMSelectInput'
import FMTextInput from 'components/reusable/formik-inputs/FMTextInput'
import { getPeriodTypes, getTermsList, IContractFormValues } from 'constants/initialValues/contractFormInitialValues'
import { useEditContractProvider } from 'contexts/EditContractContext'
import { FormikProps } from 'formik'
import useLocales from 'hooks/useLocales'
import useContractDuration from './useContractDuration'
import ExtendTerminateOption from './ExtendTerminateOption'
import { IContractStatus, ITermTypes } from '../../../../../@types/contractResponse'
import moment from 'moment'
import { useState } from 'react'

type Props = {
    formik: FormikProps<IContractFormValues>
}

const ContractDurations = ({ formik }: Props) => {
    const { selectedAction, terminated, setSelectedAction } = useContractDuration({ formik }) //Use this to handle inside date related logic
    const { setFieldValue, values } = formik
    const { contract, handleHighlight } = useEditContractProvider()
    const { translate } = useLocales()

    const [showExt, setShowExt] = useState<boolean>(false)

    const handleChange = (e: any) => {
        if (e.target.value === ITermTypes.AUTO_RENEWAL) {
            setShowExt(true)
        } else {
            setShowExt(false)
        }
    }

    const terms = getTermsList(translate)
    const periodTypes = getPeriodTypes(translate)

    const contractTermsSuggestions = contract?.aiSuggestionResponse?.contractTerms[0]
    const effectiveDatesSuggestions = contract?.aiSuggestionResponse?.effectiveDates[0]
    const endDatesSuggestions = contract?.aiSuggestionResponse?.endDates[0]
    const noticePeriodSuggestions = contract?.aiSuggestionResponse?.noticePeriod[0]

    const isExpired = values?.endDate && moment().isAfter(moment(values.endDate))
    const isAutoRenew = values.terms === ITermTypes.AUTO_RENEWAL
    const isDraft = values.contractStatus === IContractStatus.DRAFT
    const disableDateFields = isAutoRenew && isExpired || isExpired && !isDraft

    const showExtendTerminateSection = terminated || showExt || isExpired && isAutoRenew

    return (
        <Grid item md={12} spacing={2} >
            {/*---------- Contract duration ---------*/}
            <HeaderBetween>
                <Typography variant='subtitle1'>{translate('contract.contractDuration', 'Contract duration')}</Typography>
            </HeaderBetween>
            <Grid item container sm={12} spacing={2} mb={2}>
                <Grid item sm={4} container alignItems={"center"} >
                    <SelectFieldWrapper >
                        <FMSelectInput name='terms' label={translate("contractDuration.terms", "Terms")} options={terms} onChange={handleChange} />
                        {contractTermsSuggestions?.term && <MagicWand
                            keyText={contractTermsSuggestions.term}
                            lineText={contractTermsSuggestions.lineText}
                            // id={contractTermsSuggestions?.term}
                            fieldValue={contractTermsSuggestions?.term}
                            handleHighlight={handleHighlight}
                            setFieldValue={setFieldValue}
                            field='terms'
                        />}
                    </SelectFieldWrapper>
                </Grid>
                <Grid item sm={4} container alignItems={"center"} >
                    <SelectFieldWrapper >
                        <FMDatePicker label={translate('contractDuration.effectiveDate', 'Effective date')} name='effectiveDate' disabled={!!disableDateFields} />

                        {effectiveDatesSuggestions?.date && <MagicWand
                            keyText={effectiveDatesSuggestions.date}
                            lineText={effectiveDatesSuggestions.lineText}
                            // id={effectiveDatesSuggestions?.date}
                            fieldValue={effectiveDatesSuggestions?.date}
                            handleHighlight={handleHighlight}
                            setFieldValue={setFieldValue}
                            field='effectiveDate'
                        />}
                    </SelectFieldWrapper>
                </Grid>
                <Grid item sm={4} container alignItems={"center"} >
                    <SelectFieldWrapper >
                        <FMDatePicker label={translate('contractDuration.endDate', 'End date')} name="endDate" disabled={!!disableDateFields} />

                        {endDatesSuggestions?.date && <MagicWand
                            keyText={endDatesSuggestions.date}
                            lineText={endDatesSuggestions.lineText}
                            fieldValue={endDatesSuggestions?.date}
                            handleHighlight={handleHighlight}
                            setFieldValue={setFieldValue}
                            field='endDate'
                        />}
                    </SelectFieldWrapper>
                </Grid>
            </Grid>

            {/*---------- Extend or terminate---------*/}
            {showExtendTerminateSection && <ExtendTerminateOption formik={formik} selectedAction={selectedAction} setSelectedAction={setSelectedAction} />}

            {/*---------- Notice period ---------*/}
            <HeaderBetween>
                <Typography variant='subtitle1'>{translate('contract.noticePeriod', 'Notice period')}</Typography>
            </HeaderBetween>
            <Grid item container sm={12} spacing={2} mb={2}>
                <Grid item sm={6} container alignItems={"center"} >
                    <SelectFieldWrapper >
                        <FMTextInput name="noticePeriod" label={translate('contract.quantity', 'Quantity')} />

                        {noticePeriodSuggestions?.noticePeriod && <MagicWand
                            keyText={`${noticePeriodSuggestions.noticePeriod}${noticePeriodSuggestions?.noticePeriodType}'s`}
                            lineText={noticePeriodSuggestions.lineText}
                            // id={noticePeriodSuggestions?.noticePeriod}
                            fieldValue={noticePeriodSuggestions?.noticePeriod}
                            handleHighlight={handleHighlight}
                            setFieldValue={setFieldValue}
                            field='noticePeriod'
                        />}
                    </SelectFieldWrapper>
                </Grid>
                <Grid item sm={6} container alignItems={"center"} >
                    <SelectFieldWrapper >
                        <FMSelectInput name='noticePeriodType' label={translate('contract.time', 'Time')} options={periodTypes} />

                        {noticePeriodSuggestions?.noticePeriod && <MagicWand
                            keyText={`${noticePeriodSuggestions.noticePeriod}${noticePeriodSuggestions?.noticePeriodType}'s`}
                            lineText={noticePeriodSuggestions.lineText}
                            // id={noticePeriodSuggestions?.noticePeriod}
                            fieldValue={noticePeriodSuggestions?.noticePeriodType}
                            handleHighlight={handleHighlight}
                            setFieldValue={setFieldValue}
                            field='noticePeriodType'
                        />}
                    </SelectFieldWrapper>
                </Grid>
            </Grid>

            {/*---------- Reminder ---------*/}
            <HeaderBetween>
                <Typography variant='subtitle1'>{translate('contract.reminder', 'Reminder')}</Typography>
            </HeaderBetween>
            <Grid item container sm={12} spacing={2} mb={2}>
                <Grid item sm={6} container alignItems={"center"} >
                    <SelectFieldWrapper >
                        <FMDatePicker label={translate('contractDuration.reminderDate', 'Reminder date')} name="emailReminderDate" />
                    </SelectFieldWrapper>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ContractDurations


const HeaderBetween = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    ".MuiTypography-root": {
        margin: 0,
        padding: 0,
        color: theme.palette.grey[600]
    }
}))

const SelectFieldWrapper = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    '.MuiInputBase-root': {
        flex: 1,
        // transform: 'translate(3px, -5px) scale(0.75)'
    }
}))