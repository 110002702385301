import { number } from "yup";
import { IPartyItem, IPartyItemValues } from "../../@types/party";

export const counterPartiesInitialValues = (data: IPartyItem | undefined) => {
    const fields: Partial<IPartyItemValues> = {
        name: "",
        number: "",
        partyRole: 1
    }
    if (data) {
        fields.name = data.name
        fields.number = data.number
        fields.partyRole = data.partyRole.id
    }

    return fields
}
export const partyColumns = (translate: any) => {
    const headers = [
        {
            text: translate('user.Name', 'Name'),
            sortable: false,
        },
        {
            text: translate('user.organizationNumber', 'Organization Number'),
            sortable: false,
        },
        {
            text: translate('user.contracts', 'Contracts'),
            sortable: false,
        }, {
            text: translate('user.createdAt', 'Created at'),
            sortable: false,
        }, {
            text: translate('user.lastModifiedAt', 'Last modified at'),
            sortable: false,
        },
        {
            text: '',
            sortable: false,
        },
    ];
    return headers
}

export const partyContactColumns = (translate: any) => {
    const headers = [
        {
            text: translate('contact.firstName', 'First name'),
            sortable: false,
        }, {
            text: translate('contact.lastName', 'Last name'),
            sortable: false,
        },
        {
            text: translate('contact.email', 'Email'),
            sortable: false,
        },
        {
            text: translate('contact.phone', 'Phone'),
            sortable: false,
        },
        {
            text: '',
            sortable: false,
        },
    ];
    return headers
}

export const partyContractColumns = (translate: any) => ([
    { text: translate('common.title', 'Title'), sortable: true, field: 'title' },
    { text: translate('contract.typeLable', 'Type'), sortable: true, field: 'type' },
    { text: translate('contract.owner', 'Contract owner'), sortable: true, field: 'contract_owner' },
    { text: translate('contract.Counterparty', 'Counterparty'), sortable: true, field: 'counter_party' },
    { text: translate('common.endDate', 'End date'), sortable: true, field: 'end_date' },
    { text: translate('common.notice', 'Notice'), sortable: true, field: 'notice' },
    { text: translate('common.status', 'Status'), sortable: true, field: 'status' },
    // { text: '', sortable: false }
]);