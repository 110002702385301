import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// 
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import { showFilter, hideFilter } from 'redux/slices/searchContract';
import MobileMenu from './navbar/mobile-menu'
import { AuthSharedContext } from 'contexts/SharedContext';
import UseLargerScreenMessage from 'components/UseLargerScreenMessage';
import ProductTourWelcomeDialog from '../../../src/components/product-tour/ProductTourWelcomeDialog';
import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'redux/store';
import { clearError } from 'redux/slices/errorSlice';
import ErrorBoundary from '../../ErrorBoundary';
import PageLoadError from 'pages/PageLoadError';
import Page403 from 'pages/Page403';
import Page404 from 'pages/Page404';
import Page500 from 'pages/Page404';
import Gleap from 'gleap';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  // paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingTop: "2rem",
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    // paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingTop: "2rem",
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const { user } = useAuth();

  const isDesktop = useResponsive('up', 'md');

  const dispatch = useDispatch();
  const location = useLocation();

  const [openModal, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const [openTourDialog, setOpenTourDialog] = useState(false);

  const { isForbidden, isPageLoadError, isPageNotFound, isInternalServerError } = useSelector((store) => store.errorStore);

  useEffect(() => {
    dispatch(clearError());
  }, [location.pathname]);

  useEffect(() => {
    if (user?.uuid) {
      Gleap.identify(user.uuid, {
        name: `${user?.userInfo?.firstName ?? ""} ${user?.userInfo?.lastName ?? ""}`.trim(),
        email: user?.userInfo?.email ?? "",
        companyName: user?.organization?.name ?? "",
        phone: user?.userInfo?.mobileNo ?? "",
        companyId: user?.organization?.uuid ?? "",
      });
    }
  }, [user]);

  const handleNavbarLoaded = () => {
    const productTour = JSON.parse(localStorage.getItem('productTour') || '{}');
    const userEmails = ['pathum+1@codebuilders.se', 'martin.strand@tagd.ai'];
    const userEmail = user?.userInfo?.email || '';

    // Check if the tour is paused or if no productTour key exists
    if (productTour.status !== 'started' && userEmails.includes(userEmail)) {
      setOpenTourDialog(true);
    }
  };

  const onClose = () => {
    console.log("On close")
  }

  //Render error pages
  const renderErrorPage = (ErrorComponent: React.FC) => (
    <Box>
      {verticalLayout ? (
        <NavbarHorizontal onLoaded={handleNavbarLoaded} />
      ) : (
        <NavbarVertical isOpenSidebar={openModal} onCloseSidebar={() => setOpen(false)} />
      )}
      <ErrorComponent />
    </Box>
  );

  if (isForbidden) return renderErrorPage(Page403);
  if (isPageLoadError) return renderErrorPage(PageLoadError);
  if (isPageNotFound) return renderErrorPage(Page404);
  if (isInternalServerError) return renderErrorPage(Page500);


  if (verticalLayout) {
    return (
      <>
        <AuthSharedContext>
          {/* {isDesktop ? <NavbarHorizontal /> : <MobileMenu />} */}
          <NavbarHorizontal onLoaded={handleNavbarLoaded} />
          {/* <ProductTourWelcomeDialog openTourDialog={openTourDialog} onClose={onClose} /> */}
          <Box
            component="main"
            sx={{
              px: { lg: 2 },
              pt: {
                xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px`,
              },
              pb: {
                xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT - 30}px`,
              },
            }}
          >
            {isDesktop ? (<Outlet />) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <UseLargerScreenMessage />
              </Box>
            )}
          </Box>
        </AuthSharedContext>
      </>
    );
  }

  return (
    <AuthSharedContext>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
          // border:"10px solid red"
        }}
      >
        {/* <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} /> */}

        <NavbarVertical isOpenSidebar={openModal} onCloseSidebar={() => setOpen(false)} />

        <ErrorBoundary>
          <MainStyle collapseClick={collapseClick}>
            <Outlet />
          </MainStyle>
        </ErrorBoundary>
      </Box>
    </AuthSharedContext>
  );
}
