import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig, AxiosError, Method } from 'axios'
import { privateAxiosInstance } from 'utils/axios'
import { store } from 'redux/store';
import { setForbidden, setPageNotFound, setInternalServerError } from 'redux/slices/errorSlice';

export const axiosBaseQuery = (): BaseQueryFn<
    {
        url: string
        method?: Method
        data?: AxiosRequestConfig['data']
        params?: AxiosRequestConfig['params']
        headers?: AxiosRequestConfig['headers']
    },
    unknown,
    unknown
> => async ({ url, method, data, params, headers }) => {
    try {
        const result = await privateAxiosInstance({
            url: url,
            method,
            data,
            params,
            headers,
        })

        return { data: result.data }
    } catch (axiosError) {
        const err = axiosError as AxiosError
        const status = err.response?.status;
        if (status === 403) {
            store.dispatch(setForbidden(true));
        } else if (status === 404) {
            store.dispatch(setPageNotFound(true));
        } else if (status === 500) {
            store.dispatch(setInternalServerError(true));
        }

        return {
            error: {
                status: status,
                data: err.response?.data || err.message,
            },
        }
    }
}