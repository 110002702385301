import { useEffect, useState } from 'react';
import { rankWith, scopeEndsWith } from '@jsonforms/core';
import { materialRenderers } from '@jsonforms/material-renderers';
import { JFWandItem, JFLabel, JFParagraph } from 'components/jsonform';
import { Client } from '@stomp/stompjs';
import { useGenerateAnalysisMutation, useLazyContractInsightFieldsQuery, useLazyFetchContractCustomFieldsQuery, useUpdateContractCustomFieldsMutation } from 'redux/api/contractApi';
import { WEB_SOCKET_URL } from '../config';
import useLocales from './useLocales';
import { IGenerateType, ITabType } from '../@types/contract';
import useAPIError from './useAPIError';
import { useSnackbar } from 'notistack';
import { compareObjectsValueDiff } from 'utils';


type Props = {
  contractId: string;
  contractTypeId: string;
  type: ITabType
  setTabValueToGeneral?: () => void
};

type KeyValue = {
  uuid: string;
  value: string;
};

const useAIBoost = ({ contractId, contractTypeId, type, setTabValueToGeneral }: Props) => {
  const { translate } = useLocales();
  const { handleError } = useAPIError()
  const { enqueueSnackbar } = useSnackbar();
  const [fetchContractCustomFields, { data: customFieldDetails, isLoading: isLoadingDetails, isSuccess: isSuccessDetails, }] = useLazyFetchContractCustomFieldsQuery();
  const [fetchContractInsightFields, { data: InsightField, isLoading: isLoadingInsight, isSuccess: isSuccessInsight, }] = useLazyContractInsightFieldsQuery();
  const [generateAnalysis, { isLoading: isLoadingGenerate }] = useGenerateAnalysisMutation()
  const [updateContract, { isError, isLoading: isLoadingUpdate }] = useUpdateContractCustomFieldsMutation();

  const [loading, setLoading] = useState(true) //This loading use for until websocket connect to show spinner
  console.log("isLoadingDetails", isLoadingDetails, isLoadingInsight, loading);

  const customField = customFieldDetails || InsightField
  const isLoading = isLoadingDetails || isLoadingInsight || loading
  const isSuccess = isSuccessDetails || isSuccessInsight

  const [aiReady, setAiReady] = useState<boolean>(false);
  const [analyzing, setAnalyzing] = useState<boolean>(false);
  const [insightsReady, setInsightsReady] = useState<boolean>(false);
  const [data, setData] = useState(null);
  const [schema, setSchema] = useState(null);
  const [UISchema, setUISchema] = useState(null);
  const [existData, setExistData] = useState(null)

  const handleReGenerateAnalysis = async (type?: IGenerateType) => {
    try {
      const payload: any = { contractId, payload: { type } }
      if (type === IGenerateType.CUSTOM || type === IGenerateType.DETAILS) {
        payload.payload = { ...payload.payload, contractTypeId: contractTypeId }
      }
      await generateAnalysis(payload).unwrap()
    } catch (error) {
      console.log(error);
      handleError(error)
    }
  }

  useEffect(() => {
    if (!existData) {
      setExistData(data)
    }
  }, [setExistData, data, existData])

  const updateCustomFields = async () => {

    const diff = existData && data && compareObjectsValueDiff(existData, data)

    const customFieldsArray: KeyValue[] = [];
    if (diff && Object.keys(diff).length > 0) {
      Object.entries(diff).forEach(([key, value]) => {
        const typedValue = value as unknown as {
          answer: string;
          uuid: string;
        };
        customFieldsArray.push({ uuid: typedValue.uuid, value: typedValue.answer });
      });
    }

    try {
      const response = await updateContract({
        contractId: contractId!,
        payload: { data: customFieldsArray },
      });

      if (Object.keys(response)?.length) {
        enqueueSnackbar(translate('contract.updated', 'Custom fields successfully updated.'));
      }
      setTabValueToGeneral && setTabValueToGeneral()
    } catch {
      enqueueSnackbar(translate('error.serverError', 'Something went wrong!'), {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (aiReady) {
      fetchContractCustomFields({ contractId: contractId, params: { contractTypeId, type } })
        .then(res => {
          setLoading(false)
          setAiReady(false)
        });
    }
  }, [aiReady, fetchContractCustomFields, contractId, contractTypeId, type])

  useEffect(() => {
    if (insightsReady) {
      fetchContractInsightFields({ contractId: contractId, type: type })
        .then(res => {
          setLoading(false)
          setInsightsReady(false)
        });
    }
  }, [insightsReady, fetchContractInsightFields, contractId, type])


  useEffect(() => {
    const client = new Client({
      brokerURL: WEB_SOCKET_URL,
      connectHeaders: {},
      debug: function (str) {
        console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.onConnect = () => {

      if (type === ITabType.details) {
        client.subscribe(`/topic/ai-boost/${contractId}`, (message) => {
          if (message.body === 'PROCESSING') {
            setAnalyzing(true)
            setLoading(false)
          }
          if (message.body === 'PROCESSING_COMPLETE') {
            setAiReady(true)
            setAnalyzing(false)
          }

        });
      }

      if (type === ITabType.custom) {
        client.subscribe(`/topic/ai-custom/${contractId}`, (message) => {
          if (message.body === 'PROCESSING') {
            setAnalyzing(true)
            setLoading(false)
          }
          if (message.body === 'PROCESSING_COMPLETE') {
            setAiReady(true)
            setAnalyzing(false)
          }

        });
      }

      if (type === ITabType.insights) {
        client.subscribe(`/topic/ai-insights/${contractId}`, (message) => {
          if (message.body === 'PROCESSING') {
            setAnalyzing(true)
            setLoading(false)
          }
          if (message.body === 'PROCESSING_COMPLETE') {
            setInsightsReady(true)
            setAnalyzing(false)
          }
        });
      }
    };

    client.onStompError = (frame) => {
      console.error('Broker reported error: ' + frame.headers);
      console.error('Additional details: ' + frame.body);
    };

    client.activate();

    return () => {
      client.deactivate();
    };
  }, [contractId, type]);

  useEffect(() => {
    if (customField && customField?.data) {
      const dataSet = { ...customField.data }

      for (let item in customField.data) {
        dataSet[item] = {
          ...dataSet[item],
          lineText: {
            ...dataSet[item].lineText,
            answer: dataSet[item].answer,
            paragraph: dataSet[item].paragraph
          }
        }
      }

      setData(dataSet);
    }
    if (customField && customField?.schema) {

      const convertObj = (arg: any) => {
        const orgObject = { ...arg.properties }
        for (let item in arg.properties) {
          orgObject[item] = {
            ...arg.properties[item], properties: {
              ...arg.properties[item].properties,
              answer: {
                ...arg.properties[item].properties.answer,
                ...(arg.properties[item].properties.answer?.oneOf && { // Conditionally add oneOf
                  oneOf: arg.properties[item].properties.answer.oneOf.map((e: any) => ({
                    const: e.const,
                    title: translate(e.title, e.const)
                  }))
                })

              }
            }
          }
        }
        return orgObject
      }
      const updatedSchema = {
        ...customField.schema,
        properties: convertObj(customField.schema)
      }

      setSchema(updatedSchema);
    }
    if (customField && customField?.ui) {
      const uiEl = {
        ...customField.ui,
        elements: customField.ui.elements.map((e: any) => {
          return {
            ...e,
            elements: e.elements.map((a: any) => {
              const itemEl = a.scope.split('/')[2];
              return {
                ...a,
                options: {
                  translationKey: customField?.data[itemEl]?.translationKey,
                },
              };
            }),
          };
        }),
      };
      setUISchema(uiEl);
    }
  }, [customField, translate]);

  const handleMouseOver = (e: any) => {
    console.log(e);
  };
  const renderers = [
    ...materialRenderers,
    {
      tester: rankWith(5, scopeEndsWith('lineText')),
      renderer: JFWandItem,
      onmouseover: handleMouseOver,
    },
    {
      tester: rankWith(3, scopeEndsWith('paragraph')),
      renderer: JFParagraph,
    },
    {
      tester: rankWith(3, scopeEndsWith('name')),
      renderer: JFLabel,
    },
  ];
  return {
    data,
    schema,
    UISchema,
    renderers,
    setData,
    isLoading,
    isSuccess,
    isLoadingUpdate,
    customField,
    analyzing,
    handleReGenerateAnalysis,
    isLoadingGenerate,
    updateCustomFields
  };
};

export default useAIBoost;
