import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";

type IUserGroups = { createdAt: string, createdBy: string, default: boolean, id: number, members: number, userGroupName: string }

export const groupAPI = createApi({
    reducerPath: "API/GROUP",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['userGroups', 'userGroupsList'],
    endpoints(builder) {
        return {
            fetchUserGroups: builder.query<IUserGroups[], void>({
                query() {

                    let endpointURL = `/us-api/user-group`;

                    return {
                        url: endpointURL
                    }
                },
                transformResponse(response: any) {
                    return response.content
                },
                providesTags: ["userGroups"],
                keepUnusedDataFor: 60 * 10 // 10Min keep cache
            }),

            fetchGroupsList: builder.query<any, { page?: number; rowsPerPage?: number; list?: boolean }>({
                query({ page = 1, rowsPerPage = 10, list = false }) {
                    return {
                        url: `/us-api/user-group?pageNumber=${page}&pageSize=${rowsPerPage}&list=${list}`,
                    };
                },
                transformResponse(response: any) {
                    return {
                        rows: response.content,
                        totalRecords: response.pagination.totalRecords,
                    };
                },
                providesTags: ['userGroupsList'], // Cache invalidation tags
                keepUnusedDataFor: 60 * 10, // Cache data for 10 minutes
            }),

        }
    }
})

export const { useFetchUserGroupsQuery, useFetchGroupsListQuery } = groupAPI