import React, { Fragment } from 'react';

import useLocales from 'hooks/useLocales';
import Label from 'components/Label';
import { useTheme } from '@mui/material/styles';

type Props = {
  recordType: string;
};

const getColor = (color: string) => ({
  height: '22px',
  minWidth: '30px',
  borderRadius: '6px',
  alignItems: 'center',
  display: 'inline-flex',
  padding: '3px 10px',
  color: '#fff',
  fontSize: '0.75rem',
  fontWeight: '700',
  backgroundColor: color,
});

export default function ContractRecordType(props: Props) {
  const theme = useTheme();

  const { translate } = useLocales();
  const { recordType } = props;
  let LabelComponent;

  switch (recordType) {
    case 'APPENDIX':
      LabelComponent = (
        <Fragment>
          <span >
            {translate('contractRecordType.update', 'Update')}
          </span>
        </Fragment>
      );
      break;

    case 'PROCESSING':
      LabelComponent = (
        <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }} >
          {/* <div>
            {translate('contractRecordType.update', 'Update')}
          </div> */}
          {/* {' '} */}
          <div
            style={{
              color: '#0C53B7',
              // paddingRight: '0.5rem',
              paddingLeft: '0.5rem',
              // marginLeft:'auto'
            }}
          >
            <Label
              color="info"
              sx={{ fontSize: (theme) => theme?.customPalette?.taskTable?.analyzingFontSize ?? '' }}
            >
              <span style={{ padding: '0 0.3rem' }}>
                {translate('contractRecordType.analyzing', 'Analyzing')}
              </span>
              <div className="loader"></div>
            </Label>
          </div>
        </div>
      );
      break;

    case 'ANALYSIS_FAILED':
      LabelComponent = (
        <Label
          color="warning"
        >
          <span style={{ padding: '0 0.3rem' }}>
            {translate('contractRecordType.analysisFailed', 'Analysis failed')}
          </span>
        </Label>
      )
      break;

    case 'CONTRACT':
    case null:
      LabelComponent = (
        <span
        // style={getColor(theme.palette.warning.main)}
        >
          {translate('contractRecordType.contract', 'Contract')}
        </span>
      );
      break;

    default:
      break;
  }

  return (

    <div style={{ whiteSpace: 'nowrap', paddingTop: '.4rem', width: '100%' }}>{LabelComponent}</div>

  );
}
