import { SharedContext } from 'contexts/SharedContext'
import { IContractFolderItem, IContractItem, IFolder } from '../@types/contract'
import React from 'react'
import { privateAxiosInstance } from 'utils/axios'
import { useSnackbar } from 'notistack'
import useLocales from './useLocales'
import { useDispatch } from 'redux/store'
import { removeContentRow } from 'redux/slices/contents/contentSlice'
import { useFetchFolderQuery, folderAPI } from 'redux/api/folderAPI';
import { contentApi } from 'redux/api/contentApi'
import { useParams } from 'react-router-dom';

type Props = {
    item: IContractItem | IContractItem[],
    handleSelectedFolderOutside?: (folder: IContractFolderItem | undefined | null) => void,
    handleCloseMoveDialog?: () => void,
    listView?: boolean
}

const useMoveFolder = ({ item, handleSelectedFolderOutside, handleCloseMoveDialog, listView }: Props) => {
    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { inbox } = React.useContext(SharedContext)
    const { folderId } = useParams();

    const [openNewFolderDialog, setOpenNewFolderDialog] = React.useState<boolean>(false);
    const [folders, setFolders] = React.useState<IContractFolderItem[]>([])
    const [selectFolder, setSelectFolder] = React.useState<IContractFolderItem>()
    const { data: folder, isFetching: isFolderLoading } = useFetchFolderQuery(selectFolder?.uuid ? selectFolder.uuid : "", { skip: !selectFolder?.uuid })

    const [goIn, setGoIn] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState(false)
    const [loadingFolder, setLoadingFolder] = React.useState(false)

    const getFolders = React.useCallback(async (id?: string): Promise<IContractFolderItem[]> => {
        try {
            setSelectFolder(undefined)
            setLoading(true)
            let response = null

            if (id) {
                response = await privateAxiosInstance.get(`/cs-api/folders?inboxUuid=${inbox?.uuid}&parentFolderUuid=${id}`)
            } else {
                response = await privateAxiosInstance.get(`/cs-api/folders?inboxUuid=${inbox?.uuid}`)
            }

            const data: IContractFolderItem[] = response?.data?.content ?? [];
            setFolders(data)
            if (id) {
                const folderResponse = await privateAxiosInstance.get(`/cs-api/folders/${id}`);
                setSelectFolder(folderResponse.data?.content)
            }

            return data;

        } catch (error) {
            console.log(error);
            return [];
        } finally {
            setLoading(false)
        }
    }, [])

    React.useEffect(() => {
        getFolders().catch(console.error)
    }, [getFolders])

    const handleBackToFolder = async (e: React.UIEvent) => {
        e.preventDefault()
        try {
            setGoIn(false)
            await getFolders()
        } catch (error) {
            console.error(error);
        }
    }

    const handleDoubleClickedItem = async (folderItem: IContractFolderItem) => {
        try {
            setGoIn(true)
            await getFolders(folderItem.uuid)
        } catch (error) {
            console.error(error);

        }
    }

    const handleSetSelectItem = async (folderItem: IContractFolderItem) => {
        try {
            setSelectFolder(undefined)
            let folderPath: IFolder[] = []
            if (selectFolder?.folderPath) {
                folderPath = [...selectFolder.folderPath]
            }

            const isSameParent = folderPath.find(e => e.parentFolder?.id === folderItem.parentFolder?.id)

            if (isSameParent) {
                folderPath = folderPath.filter(e => e.id !== isSameParent.id)
            }

            const hasFolder = folderPath.find(e => e.id === folderItem.id)
            if (!hasFolder) {
                folderPath.push({
                    id: folderItem.id,
                    name: folderItem.name,
                    uuid: folderItem.uuid,
                    parentFolder: folderItem?.parentFolder
                })
            }


            setSelectFolder({ ...folderItem, folderPath: folderPath })
            // const { data } = await privateAxiosInstance.get(`/cs-api/folders/${folderItem?.uuid}`);
            // setSelectFolder(data.content) // This is required to set after response, otherwise breadcrumb path will missing
        } catch (error) {
            console.error(error);

        }
    }

    const handleMoveFolderSave = async () => {
        try {
            setLoadingFolder(true)
            if (handleSelectedFolderOutside) {
                handleSelectedFolderOutside(selectFolder ? selectFolder : null)
            } else {
                if (Array.isArray(item)) {
                    //TODO: new logic to move nested of items
                } else {
                    let payload: any = {
                        "content": [],
                        "destinationFolderUuid": selectFolder?.uuid ? selectFolder?.uuid : null,
                        "destinationInboxUuid": inbox?.uuid
                    }
                    if (item.contentType === "contract" || item.recordType === "CONTRACT") {

                        payload = {
                            ...payload, content: [{
                                uuid: item.uuid,
                                contentType: "contract"
                            }]
                        }


                    } else {
                        payload = {
                            ...payload, content: [{
                                uuid: item.uuid,
                                contentType: "folder"
                            }]
                        }

                    }
                    const response = await privateAxiosInstance.put('/cs-api/contents/location', payload)
                    if (listView) {
                        dispatch(removeContentRow([{ id: +item.id }]))
                    }

                    if (response?.status === 200) {
                        if (selectFolder?.uuid) {
                            dispatch(
                                contentApi.util.invalidateTags([{ type: 'contents', id: selectFolder?.uuid }])
                            );
                        }
                        dispatch(contentApi.util.invalidateTags([{ type: 'contents', id: folderId }])); //Api call to refetch and reset the folder data that the  user has moved the content from

                        // Invalidate folderAPI to refetch the selected folder data
                        if (item?.uuid) {
                            dispatch(folderAPI.util.invalidateTags([{ type: 'Folder', id: item.uuid }]));
                        }
                    }
                }

                await getFolders(selectFolder?.uuid)


                enqueueSnackbar(translate("common.updated", "Updated"), {
                    variant: 'success',
                });
            }
            if (handleCloseMoveDialog) {
                handleCloseMoveDialog()
            }
            setLoadingFolder(false)
        } catch (error) {
            setLoadingFolder(false)
            enqueueSnackbar(translate("moveFolder.movedError", "Moved failed"), {
                variant: 'error',
            });
        }

    }

    return ({
        folder,
        getFolders,
        handleBackToFolder,
        handleDoubleClickedItem,
        handleSetSelectItem,
        handleMoveFolderSave,
        loading, setLoading,
        loadingFolder, setLoadingFolder,
        goIn, setGoIn,
        folders, setFolders,
        selectFolder, setSelectFolder,
        openNewFolderDialog,
        setOpenNewFolderDialog,
        isFolderLoading
    })
}

export default useMoveFolder