import { withJsonFormsControlProps } from '@jsonforms/react';
import { Box, styled } from '@mui/material';
import React from 'react'
import useLocales from 'hooks/useLocales';
import { useContract } from 'pages/contracts/contract-form/contract-provider';
import MagicWand from 'components/contract/MagicWand';
import moment from 'moment';

type Props = {
  data: any;
  handleChange(path: string, value: any): void;
  path: string;
};

const WandItem: React.FC<Props> = (props) => {
  const { translate } = useLocales()
  const { data } = props;

  let lineText: string = ""
  let source: string = ""
  let moreText = ""

  if (typeof data === "string") {
    lineText = data
  } else {
    if (data.lastModifiedBy !== "1") {
      lineText = translate("customFields.TheValueHasBeenManuallyModifiedOn", "The value has been manually modified on") + " " + moment(data?.lastModifiedAt).format('YYYY-MM-DD')
      source = translate("customFields.source", "Source") + ": " + translate("customFields.userInput", "USER INPUT")
    } else if (data?.reasoning) {
      lineText = data?.reasoning
      source = translate("customFields.source", "Source") + ": " + translate("customFields.aiReasoning", "AI REASONING")
      moreText = translate("customFields.page", "Page") + ": " + data?.source
    } else {
      lineText = data?.text
      source = translate("customFields.source", "Source") + ": " + translate("customFields.contractText", "CONTRACT TEXT")
      moreText = ` ${translate("customFields.page", "Page")}: ${data?.source}, ${translate("customFields.paragraph", "Paragraph")}: ${data?.paragraph}`
    }
  }


  const contract: any = useContract();
  const showWand = data.answer.toLowerCase().trim() !== "n/a"
  return data && lineText && showWand && <MagicWand
    lineText={lineText}
    handleHighlight={contract.handleHighlight}
    field='contractType'
    headerText={source}
    moreText={moreText}
  />

};

export const JFWandItem = withJsonFormsControlProps(WandItem);

const Label: React.FC<any> = (data) => {
  const { translate } = useLocales()
  const translationKey = data?.uischema?.options?.translationKey

  return <Box>{translationKey ? translate(translationKey, data.data) : data.data}</Box>;
};

export const JFLabel = withJsonFormsControlProps(Label);

const Paragraph: React.FC<Props> = ({ data }) => {
  return <ParaBody>{/* § {data} */}</ParaBody>;
};

export const JFParagraph = withJsonFormsControlProps(Paragraph);


const ParaBody = styled("div")(() => ({
}))
