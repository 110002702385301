import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
// import { rootPersistConfig, rootReducer } from './rootReducer';

import { contentStore } from './slices/contents/contentSlice';
import searchContract from './slices/searchContract';
import staticsStorage from './slices/staticsStorage';
import { permissionsStore } from './slices/permissions/permissionsSlice';
import { contractStore } from './slices/contracts/contractSlice';
import { folderAPI } from './api/folderAPI';
import { sharedStore } from './slices/shared/sharedSlice';
import { contentApi } from './api/contentApi';
import { contractApi } from './api/contractApi';
import { userApi } from './api/userAPI';
import { groupAPI } from './api/groupAPI';
import { tagApi } from './api/tagAPI';
import { organizationApi } from './api/organizationsAPI';
import { contractCategoriesApi } from './api/contractCategoriesAPI';
import { partyAPI } from './api/partyAPI';
import { storageAPI } from './api/storageAPI';
import { contentPermissionAPI } from './api/contentPermission';
import { languageApi } from './api/languageApi';
import { contactApi } from './api/contactApi';
import { errorStore } from './slices/errorSlice';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  searchContract: searchContract,
  staticsStorage: staticsStorage,
  contentStore,
  permissionsStore,
  sharedStore,
  contractStore,
  errorStore,
  [folderAPI.reducerPath]: folderAPI.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [contractApi.reducerPath]: contractApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [groupAPI.reducerPath]: groupAPI.reducer,
  [tagApi.reducerPath]: tagApi.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [contractCategoriesApi.reducerPath]: contractCategoriesApi.reducer,
  [partyAPI.reducerPath]: partyAPI.reducer,
  [contentPermissionAPI.reducerPath]: contentPermissionAPI.reducer,
  [storageAPI.reducerPath]: storageAPI.reducer,
  [languageApi.reducerPath]: languageApi.reducer,
  [contactApi.reducerPath]: contactApi.reducer,
});

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      folderAPI.middleware,
      contentApi.middleware,
      contractApi.middleware,
      userApi.middleware,
      groupAPI.middleware,
      tagApi.middleware,
      organizationApi.middleware,
      contractCategoriesApi.middleware,
      partyAPI.middleware,
      storageAPI.middleware,
      contentPermissionAPI.middleware,
      languageApi.middleware,
      contactApi.middleware
    ),
  devTools: process.env.NODE_ENV !== "production"
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, persistor, dispatch, useSelector, useDispatch, rootReducer };
