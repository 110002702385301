import React, { useContext, useState } from 'react';
import { Button, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IContractFolderItem, IContractItem } from '../../../@types/contract';
import useLocales from 'hooks/useLocales';
import Divider from '@mui/material/Divider';
import useMoveFolder from 'hooks/useMoveFolder';
import FolderBreadcrumb from './folder-breadcrumb';
import FolderContainer from './folder-conteiner';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CreateNewFolder from './CreateNewFolder';
import { useFetchFolderQuery } from 'redux/api/folderAPI';
import { SharedContext } from 'contexts/SharedContext';
import { CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

type Props = {
  handleCloseMoveDialog: () => void;
  open: boolean;
  item: IContractItem;
  handleSelectedFolderOutside?: (folder: IContractFolderItem | undefined) => void;
  titleTip?: string;
  listView?: boolean;
  setNewFolderCreated?: (value: boolean) => void;
};

const ManageMoveFolder: React.FC<Props> = ({ open, handleCloseMoveDialog, item, handleSelectedFolderOutside, titleTip, listView, setNewFolderCreated }) => {
  const { translate } = useLocales();
  const { inbox } = useContext(SharedContext)

  const {
    folder,
    folders,
    loading,
    goIn,
    selectFolder,
    openNewFolderDialog,
    getFolders,
    handleBackToFolder,
    handleDoubleClickedItem,
    handleSetSelectItem,
    handleMoveFolderSave,
    setOpenNewFolderDialog,
    loadingFolder
  } = useMoveFolder({
    item,
    listView,
    handleCloseMoveDialog,
    handleSelectedFolderOutside,
  });

  const handleCloseFolderDialog = () => {
    setOpenNewFolderDialog(false);
  };

  let isEditor = false
  if (selectFolder?.uuid && folder?.permission?.permissionName === "Editor") {
    isEditor = true
  } else if (!selectFolder && inbox?.permission?.permissionName === "Editor") {
    isEditor = true
  } else {
    isEditor = false
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseMoveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          {titleTip ? titleTip : translate('moveFolder.move', 'Move')}:{' '}
          {item.name ? item.name : item.contractDetail?.title}
          <FolderBreadcrumb
            goIn={goIn}
            selectFolder={selectFolder}
            handleBackToFolder={handleBackToFolder}
            getFolders={getFolders}
          />
        </DialogTitle>
        <Divider sx={{ my: 2 }} />
        <DialogContent sx={{ mt: 0, pt: 0 }}>
          <FolderContainer
            loading={loading}
            selectFolder={selectFolder}
            handleDoubleClickedItem={handleDoubleClickedItem}
            handleSetSelectItem={handleSetSelectItem}
            folders={folders}
          />

          {openNewFolderDialog && (
            <CreateNewFolder
              selectFolder={selectFolder}
              handleClose={handleCloseFolderDialog}
              getFolders={getFolders}
              setNewFolderCreated={setNewFolderCreated}
              handleSetSelectItem={handleSetSelectItem}
            />
          )}
        </DialogContent>
        <Divider />

        <DialogActions>
          {isEditor && <Button
            sx={{ mr: "auto" }}
            startIcon={<CreateNewFolderIcon />}
            onClick={() => setOpenNewFolderDialog(true)}
            variant={'outlined'}
            disabled={loadingFolder}
          >
            {translate('contract.newFolder', 'New Folder')}
          </Button>}


          <Button disabled={loadingFolder} variant="outlined" onClick={handleCloseMoveDialog}>
            {translate('common.cancel', 'Cancel')}
          </Button>
          <LoadingButton loading={loadingFolder} variant="contained" onClick={handleMoveFolderSave} autoFocus>
            {' '}{translate('common.selectFolder', 'Select folder')}{' '}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageMoveFolder;
