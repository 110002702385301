import { useEffect, useState } from 'react';
import { rankWith, scopeEndsWith } from '@jsonforms/core';
import { materialRenderers } from '@jsonforms/material-renderers';
import { JFWandItem, JFLabel } from 'components/jsonform';
import { Client } from '@stomp/stompjs';
import { WEB_SOCKET_URL } from '../config';

const useAIAnalysisWebSocket = (params: { contractId: number } | null) => {
  const [analyzedContractId, setAnalyzedContractId] = useState<string>('');

  useEffect(() => {
    // if (params && params.contractId) {
    const client = new Client({
      brokerURL: WEB_SOCKET_URL,
      connectHeaders: {},
      debug: function (str) {
        console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.onConnect = () => {
      client.subscribe(`/topic/ai-analysis`, (message) => {
        if (message.body !== '') {
          setAnalyzedContractId(message.body);
        }
      });
    };

    client.onStompError = (frame) => {
      console.error('Broker reported error: ' + frame.headers);
      console.error('Additional details: ' + frame.body);
    };

    client.activate();

    return () => {
      client.deactivate();
    };
    // }
  }, [params?.contractId]);

  const handleMouseOver = (e: any) => {
    console.log(e);
  };
  const renderers = [
    ...materialRenderers,
    {
      tester: rankWith(5, scopeEndsWith('lineText')),
      renderer: JFWandItem,
      onmouseover: handleMouseOver,
    },
    {
      tester: rankWith(3, scopeEndsWith('paragraph')),
      renderer: JFLabel,
    },
    {
      tester: rankWith(3, scopeEndsWith('name')),
      renderer: JFLabel,
    },
  ];
  return {
    renderers,
    analyzedContractId,
  };
};

export default useAIAnalysisWebSocket;
