import { Button, Grid } from '@mui/material'
import { FormikProps } from 'formik'
import useLocales from 'hooks/useLocales'
import ContractDurations from './ContractDurations/ContractDurations'
import TagsAndDescription from './TagsAndDescription/TagsAndDescription'
import BasicDetails from './BasicDetails/BasicDetails'
import { LoadingButton } from '@mui/lab'
import { IContractFormValues } from 'constants/initialValues/contractFormInitialValues'
import LocationDetails from './LocationDetails/LocationDetails'

type Props = {
    formik: FormikProps<IContractFormValues>
}

const General = ({ formik }: Props) => {

    const { translate } = useLocales()

    return (
        <Grid item container md={12} spacing={3} mt={.1}>

            {/* --------Basic details--------- */}
            <Grid item md={12} mb={2}>
                <BasicDetails formik={formik} />
            </Grid>

            {/* --------Contract parties--------- */}
            {/* <Grid item md={12} mb={2}>
                <ContractParties />
            </Grid> */}

            {/* --------Contract duration--------- */}
            <Grid item md={12} mb={2}>
                <ContractDurations formik={formik} />
            </Grid>

            {/* --------Contract duration--------- */}
            <Grid item md={12} mb={2}>
                <TagsAndDescription formik={formik} />
            </Grid>

            {/* --------Contract Location--------- */}
            <Grid item md={12} mb={2}>
                <LocationDetails />
            </Grid>

            <Grid item md={12} mb={2} container justifyContent={"space-between"}>
                <Button variant='outlined'>
                    {translate("common.cancel", 'Cancel')}
                </Button>
                <LoadingButton type='submit' variant='contained' color='primary'>{translate("common.save", 'Save')}</LoadingButton>
            </Grid>
        </Grid >
    )
}

export default General

