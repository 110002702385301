import React, { useEffect, ReactNode } from 'react';
import { setPageLoadError } from 'redux/slices/errorSlice';
import { useDispatch } from 'redux/store';

interface Props {
  children: ReactNode;
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Custom error handler
    const errorHandler = (event: ErrorEvent) => {
      console.error('Uncaught error:', event.error);
      dispatch(setPageLoadError(true));
    };

    window.addEventListener('error', errorHandler);
    return () => window.removeEventListener('error', errorHandler);
  }, [dispatch]);

  return <>{children}</>;
};

export default ErrorBoundary;