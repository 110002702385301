import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "redux/axiosBaseQuery";
import { partyAPI } from "./partyAPI"

export type IContactFormPayload = {
    firstName: string,
    lastName: string,
    email: string,
    mobileNo: string,
    description: string
    party?: {
        uuid?: string;
    }
}
export type IContactPayload = {
    uuid: string,
    payload: IContactFormPayload
}

export const contactApi = createApi({
    reducerPath: "API/CONTACT",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['contact'],
    endpoints(builder) {
        return {
            fetchContact: builder.query<any, { id: string }>({
                query({ id }) {
                    let endpointURL = `/cs-api/contents/${id}`

                    return {
                        url: endpointURL,
                    };
                },
                providesTags: ["contact"]
            }),
            addContact: builder.mutation<string, { payload: IContactFormPayload }>({
                query: ({ payload }) => ({
                    url: `/cs-api/contacts`,
                    data: payload,
                    method: 'POST',
                }),
                invalidatesTags: ["contact"],
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    await queryFulfilled;
                    dispatch(partyAPI.util.invalidateTags(["parties"]));
                },
            }),
            updateContact: builder.mutation<string, IContactPayload>({
                query: ({ uuid, payload }) => ({
                    url: `/cs-api/contacts/${uuid}`,
                    data: payload,
                    method: 'PUT',
                }),
                invalidatesTags: ["contact"],
                async onQueryStarted(_, { dispatch, queryFulfilled }) {
                    await queryFulfilled;
                    dispatch(partyAPI.util.invalidateTags(["parties"]));
                },
            }),
            //Can write a mutation to upload file, I will replace the api?    
            uploadPdf: builder.mutation<any, { file: File, contractId: string }>({
                query: ({ file, contractId }) => {

                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('type', 'AT');

                    return ({
                        url: `cs-api/contracts/file/${contractId}`,
                        data: formData,
                        method: "PUT",
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    })
                },
                invalidatesTags: ["contact"],
            }),
            addAttachmentAsAMainFile: builder.mutation<object, { contractId: string, fileId: string }>({
                query: ({ contractId, fileId }) => ({
                    url: `/cs-api/contracts/${contractId}/file/${fileId} `,
                    method: "PUT",
                }),
                invalidatesTags: ["contact"],
            }),
        };
    }
})

export const { useFetchContactQuery, useUpdateContactMutation, useAddContactMutation, useUploadPdfMutation, useAddAttachmentAsAMainFileMutation } = contactApi