import { createSlice, Reducer, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    isForbidden: boolean;
    isPageLoadError: boolean;
    isPageNotFound: boolean;
    isInternalServerError: boolean;
}

const initialState: InitialState = {
    isForbidden: false,
    isPageLoadError: false,
    isPageNotFound: false,
    isInternalServerError: false,
};

const store = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setForbidden: (state, action: PayloadAction<boolean>) => {
            state.isForbidden = action.payload;
        },
        setPageLoadError: (state, action: PayloadAction<boolean>) => {
            state.isPageLoadError = action.payload;
        },
        setPageNotFound: (state, action: PayloadAction<boolean>) => {
            state.isPageNotFound = action.payload;
        },
        setInternalServerError: (state, action: PayloadAction<boolean>) => {
            state.isInternalServerError = action.payload;
        },
        clearError: (state) => {
            state.isForbidden = false;
            state.isPageLoadError = false;
            state.isPageNotFound = false;
            state.isInternalServerError = false;
        },
    },
});

export const { setForbidden, clearError, setPageLoadError, setPageNotFound, setInternalServerError } = store.actions;
export const errorStore: Reducer<InitialState> = store.reducer;