import * as React from 'react';
import { IContractFolderItem } from '../../../@types/contract';
import useLocales from 'hooks/useLocales';
import FolderIcon from '@mui/icons-material/Folder';
import { Box, List, ListItemText, styled } from '@mui/material';
import { ListItem } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import Spinner from 'components/Spinner';
import { useEffect, useRef } from 'react';

type Props = {
  loading: boolean;
  selectFolder: IContractFolderItem | undefined;
  folders: IContractFolderItem[];
  handleDoubleClickedItem: (folderItem: IContractFolderItem) => Promise<void>;
  handleSetSelectItem: (folderItem: IContractFolderItem) => Promise<void>;
};

const FolderContainer: React.FC<Props> = ({
  loading,
  handleDoubleClickedItem,
  handleSetSelectItem,
  selectFolder,
  folders,
}) => {
  const { translate } = useLocales();

  const folderRefs = useRef<Map<number, HTMLLIElement>>(new Map());

  // Scroll to the selected folder when it changes
  useEffect(() => {
    if (selectFolder?.id) {
      const selectedFolderElement = folderRefs.current.get(selectFolder.id);
      if (selectedFolderElement) {
        selectedFolderElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [selectFolder, folders]);

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: '#fff',
            top: 0,
            left: 0,
            zIndex: 10,
          }}
        >
          <Spinner />
        </Box>
      ) : null}
      <List sx={{ mt: 0, py: 0 }} >
        {folders.map((e) => {
          if (e.folderType === "SYSTEM_GENERATED") {
            return (
              <StyledListItem
                className={"disabled"}
                key={e.id}
                sx={{ px: 0 }}
              >
                <ListItemIcon>
                  <FolderIcon color="warning" />
                </ListItemIcon>
                <ListItemText primary={e.name} />
              </StyledListItem>
            )
          } else {
            return (
              <StyledListItem
                ref={(el) => {
                  if (el) {
                    folderRefs.current.set(e.id, el);
                  }
                }}
                onDoubleClick={() => handleDoubleClickedItem(e)}
                onClick={() => handleSetSelectItem(e)}
                className={selectFolder?.id === e.id ? 'Selected' : ''}
                key={e.id}
                sx={{ px: 0 }}
              >
                <ListItemIcon>
                  <FolderIcon color="warning" />
                </ListItemIcon>
                <ListItemText primary={e.name} />
              </StyledListItem>
            )
          }
        })}
      </List>
      {/* {!folders.length ? (
        <Alert severity="info">
          {translate('moveFolder.foldersNotAvailable', 'Folders not available.')}
        </Alert>
      ) : null} */}
    </div>
  );
};

export default FolderContainer;

const StyledListItem = styled(ListItem)({
  '.MuiButtonBase-root': {
    opacity: '0',
  },

  '&:hover': {
    backgroundColor: '#ebebeb',
    transition: 'opacity .2s ease',
    cursor: 'pointer',
  },
  '&.Selected': {
    backgroundColor: '#ebebeb',
    transition: 'opacity .2s ease',
  },
  '&.disabled': {
    filter: 'grayscale(1)',
    opacity: .5,
    color: "#b0aeae",
    '&:hover': {
      cursor: 'not-allowed',
      backgroundColor: '#ffffff',
    }
  },
  '&:hover .MuiButtonBase-root': {
    opacity: 1,
    transition: 'opacity .2s ease',
  },
});
