
import { materialCells } from '@jsonforms/material-renderers'
import { JsonForms } from '@jsonforms/react'
import { Box, Card, styled } from '@mui/material';
import useAIBoost from 'hooks/useAIBoost';
import Ajv from 'ajv';
import ajvErrors from 'ajv-errors';
import { useParams } from 'react-router';
import Spinner from 'components/Spinner';
import useLocales from 'hooks/useLocales';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { CardContent } from '@mui/material';
import { Typography } from '@mui/material';
import { IGenerateType, ITabType } from '../../../../@types/contract';
import ContractRecordType from 'pages/contracts/contract-record-type';

type Props = {
  contractTypeId: string;
  type: ITabType;
  contractType?: string
  setTabValueToGeneral?: () => void
};

const ajv = new Ajv({ allErrors: true });
ajvErrors(ajv);

const ContractAiBoostLeasing = ({ contractTypeId, type, contractType, setTabValueToGeneral }: Props) => {
  const { id } = useParams();
  const { translate } = useLocales();
  const { data, schema, UISchema, renderers, setData, isLoading, analyzing, isLoadingUpdate, handleReGenerateAnalysis, isLoadingGenerate, updateCustomFields } = useAIBoost({
    contractId: id!,
    contractTypeId,
    type,
    setTabValueToGeneral
  });

  if (isLoading) {
    return (
      <Box component={'div'} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', padding: '3rem', width: '100%' }}>
        <Spinner />
      </Box>
    );
  }

  let analyzingContent = null
  let updateButtonContent = null
  let schemaContent = null

  if ((!data || !Object.keys(data).length) && !isLoading) {
    analyzingContent = <Box sx={{ width: "100%" }}>
      <Box mt={2} >{translate('common.noData', 'No data is available')}</Box>
      <Box mt={2}>
        <LoadingButton variant='contained' loading={isLoadingGenerate} disabled={analyzing} onClick={() => handleReGenerateAnalysis(IGenerateType.DETAILS)} >
          {translate('common.generateAnalysis', 'Generate analysis')}{' '}
        </LoadingButton>
      </Box>
    </Box>
  }

  if (data && Object.keys(data)?.length) {
    updateButtonContent = <Grid container>
      <Box display="flex" justifyContent="flex-end" mt={2} ml={"auto"}>
        <LoadingButton
          loading={isLoadingUpdate || isLoading}
          variant="contained"
          onClick={updateCustomFields}
          autoFocus
        >
          {' '}
          {translate('common.saveAndReturnToGeneral', 'Save and return to General')}{' '}
        </LoadingButton>
      </Box>
    </Grid>
  }

  if (schema && UISchema && data && Object.keys(data)?.length) {
    schemaContent = <Card sx={{ my: 2, width: "100%" }}>
      <CardContent sx={{ paddingTop: 1 }}>
        <Box>
          <ContractBoostCustomHeader>
            <Box>{translate('customFields.question', 'Question')}</Box>
            <Box>{translate('customFields.value', 'Value')}</Box>
            <Box></Box>
            <Box></Box>
          </ContractBoostCustomHeader>
          <ContractAPBoostDiv>
            <JsonForms
              schema={schema}
              uischema={UISchema}
              data={data}
              renderers={renderers}
              cells={materialCells}
              ajv={ajv}
              validationMode={"NoValidation"}
              onChange={({ errors, data }) => {
                console.log("errorserrors", errors);
                setData(data)
              }}
            //   readonly={readonly ? true : false}
            />
          </ContractAPBoostDiv>
        </Box>
      </CardContent>
    </Card>
  }

  return (
    <>
      <style>
        {
          `.MuiAutocomplete-listbox {
            font-size:.875rem
          }`
        }
      </style>
      {analyzing && <Grid container item md={12} ml={-1}>
        <ContractRecordType recordType={'PROCESSING'} />
      </Grid>}

      <Grid container ml={2}>
        <Typography pt={1} variant='subtitle1' sx={{ color: 'text.secondary' }}>{translate('contract.contractType', 'Contract type')}</Typography>
        <Typography pt={1} variant='subtitle1' sx={{ color: 'text.secondary' }}>: {contractType}</Typography>
        {analyzingContent}
        {schemaContent}
        {updateButtonContent}
      </Grid>
    </>
  );
};

export default ContractAiBoostLeasing

const ContractAPBoostDiv = styled("div")(() => ({
  width: "100%",
  paddingLeft: ".5rem",
  paddingTop: "1rem",
  fontSize: ".875rem",
  "div>div>.MuiGrid-container.MuiGrid-root": {
    display: "grid",
    gridTemplateColumns: "2fr 1.5fr 50px 50px",
    alignItems: "center",
    minHeight: "70px",
    "div:nth-child(2)": {
      paddingLeft: 0,
      "&,*": {
        fontSize: ".875rem !important",
      },
      ".MuiAutocomplete-inputRoot": {
        // marginTop: "1rem"
      }
    }
  }
}))

const ContractBoostCustomHeader = styled("div")(() => ({
  width: " 100%",
  display: " grid",
  gridTemplateColumns: " 2fr 1.5fr 50px 50px",
  alignItems: " center",
  background: " #F4F6F8",
  padding: " .5rem",
  borderRadius: " 5px",
  fontWeight: "600",
  color: " #637381",
  marginTop: " 1rem",
  fontSize: ".875rem",
}))