import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
// utils
// import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
import axios from 'axios';
import axiosInstance, { privateAxiosInstance } from './../utils/axios';
import { setStaticsData } from 'redux/slices/staticsStorage';
import { HOST_API, COGNITO_API, ENVIRONMENT } from '../config';
import useLocales from '../hooks/useLocales';
import jwtDecode from 'jwt-decode';
import { useFetchOrganizationByIdQuery } from 'redux/api/organizationsAPI';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    theme: any;
    displayRetry?: boolean;
  };
  [Types.Login]: {
    user: AuthUser;
    theme: any;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const getTheme = () => {
  type TokenDataType = { theme: string };
  const accessToken = window.localStorage.getItem('accessToken');
  if (accessToken) {
    const tokenData: TokenDataType = jwtDecode(accessToken);
    window.localStorage.setItem('theme', tokenData?.theme);
    return { name: tokenData?.theme };
  }

  return { name: "verified" };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isAiBoostEnabled: 'false',
  theme: getTheme(),
  displayRetry: false,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        theme: action.payload.theme,
        displayRetry: action.payload.displayRetry ?? state.displayRetry,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        theme: action.payload.theme,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const { onChangeLang } = useLocales();
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const { data } = await axiosInstance.get('/us-api/users/profile');

          const { content } = data;
          const user = content;
          const language = content?.language.name;
          onChangeLang(language ? language : "English");

          const theme = getTheme();

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
              theme,
            },
          });
        } else {
          const queryParameters = new URLSearchParams(window.location.search);
          const code = queryParameters.get('code');

          if (code !== null) {
            await verifyCode(code);
          } else {
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null,
                theme: null,
              },
            });
            if (window.location.pathname !== '/auth/logout') {
              // window.location.href = 'https://devauth.tagd.ai/oauth2/authorize?client_id=5t3rrh35cdclr2cm856340n0e9&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fdev.tagd.ai%2Fauth';
              //this window.location.href = 'https://devauth.tagd.ai/oauth2/authorize?client_id=5t3rrh35cdclr2cm856340n0e9&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fdev.tagd.ai';
              window.location.href = COGNITO_API.cognitoUrl;
            }
          }
        }
      } catch (err) {
        console.error(err);
        // const shouldDisplayRetryButton = err?.response?.status === 422;
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            theme: null,
            displayRetry: true,
          },
        });
      }
    };

    initialize();
  }, []);


  const verifyCode = async (code: string) => {
    try {
      let accessToken!: '';

      const { data } = await axios.post(`${HOST_API}/us-api/auth-detail/exchangeCode?code=${code}${ENVIRONMENT === "local" ? '&env=local' : ''}`);
      const { access_token, refresh_token, id_token, isAiBoostEnabled, refreshTokenExpiresIn } =
        data;
      window.localStorage.setItem('refreshTokenExpiresIn', refreshTokenExpiresIn);
      window.localStorage.setItem('refreshToken', refresh_token);
      accessToken = id_token;
      setSession(accessToken);

      const profile = await axiosInstance.get('/us-api/users/profile');
      const { content }: { content: AuthUser } = profile.data;
      const language = content?.language.name;
      onChangeLang(language ? language : "English");
      const theme = getTheme();

      // @ts-ignore
      content.isAiBoostEnabled = isAiBoostEnabled;

      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: true,
          user: content!,
          theme: theme!,
        },
      });
    } catch (error) {
      throw error;
      /* if (error.response && error.response.data) {
        throw new Error(error.response.data.error_description);
      } else {
        throw new Error('Something went wrong!');
      } */
    }
  };

  const login = async (email: string, password: string) => {
    let accessToken!: '';
    const user = {};

    const payload = new FormData();

    payload.append('grant_type', 'password');
    payload.append('username', email);
    payload.append('password', password);

    try {
      const { data } = await axios.post(`${HOST_API}/us-api/auth-detail/refreshToken`, payload, {
        headers: {
          Authorization: 'Basic QVNQOlYxSTZyJWEpXjRqZkZHN2czNA==',
        },
      });
      const { access_token, refresh_token, isAiBoostEnabled } = data;

      window.localStorage.setItem('refreshToken', refresh_token);

      accessToken = access_token;

      setSession(accessToken);
      const profile = await axiosInstance.get('/us-api/users/profile');

      const { content }: { content: AuthUser } = profile.data;
      const language = content?.language.name;
      onChangeLang(language ? language : "English");
      const theme = getTheme();
      // @ts-ignore
      content.isAiBoostEnabled = isAiBoostEnabled;

      dispatch({
        type: Types.Login,
        payload: {
          user: content!,
          theme: theme!,
        },
      });
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error.response.data.error_description);
      } else {
        throw new Error('Something went wrong!');
      }
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    // const token = window.localStorage.getItem('accessToken');

    // let userForwardURI = "/"
    // if (token) {
    //   const tokenData: any = jwtDecode(token);
    //   const logoutResponse = await axiosInstance.get(`/us-api/auth-detail/logout?username=${tokenData['cognito:username']}`);
    //   userForwardURI = logoutResponse?.data?.userForwardURI;
    // }

    // axios.post(`${HOST_API}/us-api/logout`, null, {
    //   headers: {
    //     Authorization: 'Bearer ' + token,
    //   },
    // });

    setStaticsData('');
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    setSession(null);
    // axios.get(userForwardURI)

    // window.location.href = userForwardURI;
    dispatch({ type: Types.Logout });
  };

  const handleLogout = async () => {
    const token = window.localStorage.getItem('accessToken');
    const refreshToken = window.localStorage.getItem('refreshToken');

    let userForwardURI = '/';
    if (token) {
      const tokenData: any = jwtDecode(token);
      const logoutResponse = await privateAxiosInstance.get(
        `/us-api/auth-detail/logout?username=${tokenData['cognito:username']}&token=${refreshToken}`
      );
      userForwardURI = logoutResponse?.data?.userForwardURI;
    }
    logout();
    window.location.href = userForwardURI;

  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        handleLogout,
        organization,
        setOrganization
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
